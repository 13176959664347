@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&family=Secular+One&family=Itim&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.copyfont {
  font-family: 'Secular One', sans-serif;
}

.handfont {
  font-family: 'Itim', cursive;
}

html,
body,
#root {
  font-family: 'Noto Sans', sans-serif;
  height: 100%;
}

@layer base {
  .flex-row {
    @apply flex;
  }
  .flex-col {
    @apply flex;
  }
  .flex-center {
    @apply flex justify-center items-center;
  }
  .flex-center-y {
    @apply flex items-center;
  }
  .flex-center-x {
    @apply flex justify-center;
  }
}

.Modal-Portal {
  .datepicker-modal {
    position: fixed;
    top: 0;
    .datepicker .datepicker-viewport {
      height: 60vh;
    }
  }
}

@import './daisy-custom.scss';
@import './daisy-custom-admin.scss';
