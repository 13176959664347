:root {
  --animation-btn: 0;
}

:where(.table *:first-child) :where(*:first-child) :where(th, td):last-child,
:where(.table *:last-child) :where(*:last-child) :where(th, td):last-child,
:where(.table *:last-child) :where(*:last-child) :where(th, td):first-child,
:where(.table *:first-child) :where(*:first-child) :where(th, td):first-child {
  @apply rounded-none;
}

@layer components {
  .select-sm {
    line-height: 1.7rem;
  }

  .btn {
    font-weight: 500;
    height: 2.5rem;
    min-height: 2.5rem;
    @apply flex-nowrap;
  }

  .btn-primary.btn-invert {
    @apply bg-opacity-10 border-none text-primary;
  }

  .btn.btn-primary.btn-disabled {
    @apply bg-primary bg-opacity-50 text-white;
  }

  .btn-outline.btn-primary {
    @apply border-primary bg-primary bg-opacity-20 text-primary;
  }

  .text-desc {
    @apply text-sm opacity-70;
  }

  .btn-base {
    @apply btn bg-primary bg-opacity-10 flex-nowrap;
    * {
      @apply flex-nowrap whitespace-nowrap;
    }
  }

  .btn-sm {
    height: 2.2rem;
    min-height: 2.2rem;
  }

  .btn-lg {
    height: 3.5rem;
    min-height: 3.5rem;
  }

  .border-1_5,
  .checkbox,
  .btn,
  .radio,
  .input-border {
    border-width: 2px;
  }

  .input,
  .select,
  .textarea,
  .select option {
    @apply border-opacity-20 outline-none hover:outline-none focus:outline-none;
  }

  .input-error,
  .button-error,
  .select-error,
  .border-error,
  .textarea-error {
    @apply border-opacity-100;
  }

  .checkbox-primary {
    @apply border-black border-opacity-20;
  }

  .radio {
    appearance: none;

    border: 1.5px solid #d2d2d2;
    border-radius: 50%;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
    }
    &:checked {
      border-width: 3.5px;
    }
    &:checked:before {
      background: white;
      border: hsl(var(--p) / var(--tw-bg-opacity)) solid 8.5px;
      border-radius: 50%;
    }
  }

  .btn-outline {
    border: black solid 1.5px;
    @apply border-black border-opacity-20;
  }

  // MUI input style
  .mui-input {
    @apply border rounded-lg px-2;
    position: relative;
    display: inline-block;

    > span {
      @apply text-sm;
      padding: 13px 16px;
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.2s;
      transition-timing-function: ease;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      opacity: 0.5;
    }

    > input {
      @apply outline-none;
      margin: 16px 10px 4px;
    }

    > input:focus + span,
    > input:not(:placeholder-shown) + span {
      opacity: 0.7;
      transform: scale(0.65) translateY(calc(-100% + 24px)) translateX(-30px);
    }
  }
}

@layer utilities {
  // .border {
  //   border-width: 1.5px;
  // }

  .border-error {
    @apply border-opacity-100;
  }
}

// Patient custom start
h4.sm {
  font-size: 1.625rem;
  line-height: 140%;
}

p.md {
  font-size: 1rem;
  line-height: 160%;
}

.checkbox {
  border-radius: var(--rounded-checkbox);
}
